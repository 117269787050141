
import { ErrorHandlereMixin, ValidateMixin } from '@/mixins';
import {
  Component,
  Mixins,
  Ref,
} from 'vue-property-decorator';
import { UserService } from '@/modules/users/services';
import { TextField } from '@/components';
import Logo from '@/assets/icons/logo.svg?inline';
import { VForm } from '@/types';

const user_service = new UserService();
const errorHandlerMixin = new ErrorHandlereMixin();

@Component({
  components: {
    Logo,
    TextField,
  },
})
export default class UserRecovery extends Mixins(ErrorHandlereMixin, ValidateMixin) {
  @Ref()
  readonly form!: VForm;

  form_valid = false;
  loading = false;

  password = '';
  password_confirmation = '';
  token = '';
  email = '';
  error = '';

  submit() {
    user_service.set_password({
      password: this.password,
      password_confirmation: this.password_confirmation,
      token: this.$route.query.token,
      email: this.$route.query.email,
    }).then(() => {
      this.show_success('Пароль сохранен');
    }).catch((error) => {
      errorHandlerMixin.handleServerError(error);
    });
  }
}
