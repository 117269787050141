var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-card',{class:_vm.$style.card,attrs:{"max-width":"448","outlined":"","tile":""}},[_c('v-form',{ref:"form",class:_vm.$style.form,on:{"submit":function($event){$event.preventDefault();return _vm.submit()}},model:{value:(_vm.form_valid),callback:function ($$v) {_vm.form_valid=$$v},expression:"form_valid"}},[_c('Logo'),_c('v-card-title',[_c('h1',[_vm._v("Смена пароля")])]),_c('v-card-text',{staticClass:"pb-0"},[_c('TextField',{attrs:{"label":"Пароль","type":"password","require":"","outlined":"","rules":[
            v => _vm.is_require(v) || _vm.require_message,
            v => _vm.is_valid_pass(v) || _vm.invalid_password_format,
          ],"error-messages":_vm.error},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('TextField',{attrs:{"label":"Подтверждение пароля","append-icon":"mdi-eye","type":"password","require":"","outlined":"","rules":[
            v => _vm.is_require(v) || _vm.require_message,
            v => _vm.is_valid_pass(v) || _vm.invalid_password_format,
            _vm.is_equals(_vm.password, _vm.password_confirmation),
          ]},model:{value:(_vm.password_confirmation),callback:function ($$v) {_vm.password_confirmation=$$v},expression:"password_confirmation"}})],1),_c('v-card-actions',{staticClass:"flex-column",class:_vm.$style.actions},[_c('v-btn',{attrs:{"color":"secondary","type":"submit","depressed":"","width":"100%","rounded":"","large":"","loading":_vm.loading,"disabled":!_vm.form_valid}},[_vm._v(" Сохранить ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }